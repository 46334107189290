body {
  margin: 0;
  font-family: Courier;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.banner {
  display: block;
  margin-top: 20vh;
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
